import * as React from 'react';
import PropTypes from 'prop-types';
import {useLocation} from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import { Scrollbars } from 'rc-scrollbars';

import { useDispatch } from 'react-redux'
import { setProgress } from '../store/appSlice'

import Header from './Header';
// import Sidebar from './Sidebar';
// import Footer from './Footer';
// import Button from './Button';
// import { Navigate } from 'react-router-dom';

// const title = null;//"Cocktail Menu";

import './Main.css';

function Main({children}) {
  const location = useLocation();
  const dispatch = useDispatch();
  const container = React.useRef();
 
  React.useEffect(() => {
    if (location.pathname === '/' || location.pathname === ''){
      dispatch(setProgress(0));
    }

    if (container.current) {
      container.current.scrollTop();
    }
  }, [location]);

  return (
    <>
      <Header />
      <Container 
          sx={{
            justifyContent: "center", 
            // pb: 3, 
            px: 0,
            position: 'absolute',
            top: '56px',
            bottom: '84px',
          }}
        >
          <Container maxWidth="xs" style={{height: '100%'}}>
            <Scrollbars universal style={{height: '100%'}} ref={container}>
              <Box component="main" mt={1} px={2} pb={3}>
                <Grid container spacing={1} sx={{ mt: 0 }}>
                  <Grid item xs={12}>
                    <Grid
                      item
                      xs={12}
                    >
                      <Box>
                        {children}
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Scrollbars>
          </Container>
        </Container>
      <Toolbar />
    </>
  );
}

Main.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

export default Main;