import Axios from 'axios';
import {origin} from '../mocks/baseUrl';

// export const getProducts = () => fetch("http://localhost:3000/api/v1/products").then(res => res.json());
export const getProducts = async (params) => {
  const {data} = await Axios.get(`${origin}/api/v1/products`, {
    params
  });

  return data;
}