import * as React from 'react';
import { Provider } from 'react-redux'
import { Routes, Route } from 'react-router-dom';
import { ErrorBoundary } from "react-error-boundary";

import CssBaseline from '@mui/material/CssBaseline';

import ThemeProvider from './Theme';
import store from './store'
 import { PickerPage } from './pages/PickerPage';
import { PickerStep1Page } from './pages/PickerStep1Page';
import { PickerStep2Page } from './pages/PickerStep2Page';
import { PickerStep3Page } from './pages/PickerStep3Page';
import { ResultsPage } from './pages/ResultsPage';
// import { CheckoutPage } from './pages/CheckoutPage';
import { MenuPage } from './pages/MenuPage';
// import { AddToBillPage } from './pages/AddToBillPage';
// import { SplitBillPage } from './pages/SplitBillPage';
import { NotFoundPage } from './pages/NotFoundPage';

import './App.css';


export default function PickYourPour() {
  return (
    <React.StrictMode>
      <ErrorBoundary 
        fallbackRender={({ error }) => (
            <div>
              There was an error!{" "}
              <pre style={{ whiteSpace: "normal" }}>{error.message}</pre>
            </div>
          )}
        >
        <Provider store={store}>
          <ThemeProvider>
            <CssBaseline />
            <Routes>
              <Route path="/" element={<PickerPage />} />
              <Route path="/step1" element={<PickerStep1Page />} />
              <Route path="/step2" element={<PickerStep2Page />} />
              <Route path="/step3" element={<PickerStep3Page />} />
              <Route path="/results" element={<ResultsPage />} />
              {/* <Route path="/checkout" element={<CheckoutPage />} /> */}
              <Route path="/menu" element={<MenuPage />} />
              {/* <Route path="/add" element={<AddToBillPage />} /> */}
              {/* <Route path="/split" element={<SplitBillPage />} /> */}
              <Route path="*" element={<NotFoundPage />} />
            </Routes>
          </ThemeProvider>
        </Provider>
      </ErrorBoundary>
    </React.StrictMode>
  );
}
