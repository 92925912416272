import { createSlice } from "@reduxjs/toolkit";

const initState = {
  available: true,
  boozy: null,
  flavorProfile: null,
  liquors: {
    dry: { id: "dry", name: "Ingenting, det skal være tørt", value: true },
    offdry: {
      id: "offdry",
      name: "Litt sødme, men fremdeles tørt",
      value: true,
    },
    semisweet: {
      id: "semisweet",
      name: "Det som smaker litt søtt",
      value: true,
    },
    // tequila: {id: 'tequila', name: 'Tequila', value: true},
    // cognac: {id: 'cognac', name: 'Cognac', value: true},
    // aquavit: {id: 'aquavit', name: 'Aquavit', value: true},
    // liqueur: {id: 'liqueur', name: 'Liqueur', value: true},
    // gin: {id: 'gin', name: 'Gin', value: true},
    // wine: {id: 'wine', name: 'Wine', value: true},
  },
};

export const preferenceSlice = createSlice({
  name: "preferences",
  initialState: {
    current: { ...initState },
  },
  reducers: {
    set: (state, { payload }) => {
      state.current[payload.name] = payload.value;
      return state;
    },
    reset: (state) => {
      state.current = { ...initState };
      return state;
    },
  },
});

export const { set, reset } = preferenceSlice.actions;

export default preferenceSlice.reducer;
