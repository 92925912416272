import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import {useTheme} from '@mui/material/styles';
import './MenuToggle.css';

function MenuToggle({
  open, 
  sx = {height: '1.5rem', width: '1.5rem'}
}) {
  const theme = useTheme();
  const backgroundColor = theme.palette.text.secondary;
  
  return (
    <div className={`menu-toggle ${open ? 'open' : ''}`}>
      <Box sx={sx} className='menu-toggle__container'>
        <div className='menu-toggle__top' style={{backgroundColor}}></div>
        <div className='menu-toggle__middle' style={{backgroundColor}}></div>
        <div className='menu-toggle__bottom' style={{backgroundColor}}></div>
      </Box>
    </div>
  );
}

MenuToggle.propTypes = {
  open: PropTypes.bool,
  sx: PropTypes.object
}

export default MenuToggle;