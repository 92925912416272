import * as React from "react";
import { PropTypes } from "prop-types";
// import { styled } from '@mui/material/styles';
import Card from "@mui/material/Card";
// import Grid from '@mui/material/Grid';
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
// import CardActions from "@mui/material/CardActions";
// import Collapse from '@mui/material/Collapse';
// import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
// import LocalBarIcon from '@mui/icons-material/LocalBar';
// import AddIcon from "@mui/icons-material/Add";
// import RemoveIcon from "@mui/icons-material/Remove";
// import Checkbox from "@mui/material/Checkbox";
import { useSelector } from "react-redux";
// import { addItem, removeItem } from "../store/cartSlice";

// import PieChart from './PieChart';

// const ExpandMore = styled((props) => {
//   return <Checkbox
//     color="tertiary"
//     inputProps={{'aria-label': 'Select item'}}
//     {...props}
//   />;
// })(({ theme }) => ({
//   marginLeft: 'auto',
//   transition: theme.transitions.create('transform', {
//     duration: theme.transitions.duration.shortest,
//   }),
// }));

function DrinkCard({ product }) {
  // const dispatch = useDispatch();
  const item = useSelector(
    (state) => state.cart.currentRound.items[product.name]
  );
  // const [expanded, setExpanded] = React.useState(item && item.count > 0);

  // const pieRadius = 8;
  // const pieColors = ['#666', '#fff'];
  // const selected = (item && item.count > 0) || false;

  // const handleExpandClick = React.useCallback(
  //   (product) => {
  //     setExpanded(!expanded);
  //     if (expanded) {
  //       dispatch(removeProduct(product));
  //     } else {
  //       dispatch(addItem(product));
  //     }
  //   },
  //   [product, expanded]
  // );

  // const incrementItemCount = React.useCallback(
  //   (product) => {
  //     dispatch(addItem(product));
  //   },
  //   [product]
  // );

  // const decrementItemCount = React.useCallback(
  //   (product) => {
  //     dispatch(removeItem(product));
  //   },
  //   [product]
  // );

  // console.log(product);

  const cardContent = React.useMemo(() => {
    return (
      <Card
        sx={{ borderRadius: 5, boxShadow: 3, width: "100%", maxWidth: 425 }}
      >
        {/* TODO: <CardMedia
          component="img"
          height="194"
          image="/static/images/cards/paella.jpg"
          alt="Paella dish"
        /> */}
        <CardHeader
          sx={{ paddingBottom: 0 }}
          title={product.name}
          // action={
          //   <>
          //     <Typography display="inline" variant="body2" color="text.secondary" sx={{textAlign: 'right'}}>
          //       {product.price} {product.priceUnits}
          //     </Typography>
          //     <Checkbox
          //       color="tertiary"
          //       inputProps={{'aria-label': 'Select item'}}
          //       checked={selected}
          //       // expand={expanded}
          //       onClick={() => handleExpandClick(product)}
          //       // aria-expanded={expanded}
          //       aria-label="show more"
          //     />
          //   </>
          // }
        ></CardHeader>
        <CardContent sx={{ pb: 0, pt: 1 }}>
          <Typography
            variant="body2"
            color="text.secondary"
            style={{
              fontSize: "1.1rem",
              fontWeight: "600",
              lineHeight: "1.2",
              margin: "1rem 0px",
            }}
          >
            {product.price}
          </Typography>
          <Stack direction="row" spacing={3} alignItems="flex-start">
            <Box
              xs="auto"
              sx={{
                pt: "0.2em",
                display: "flex",
                alignItems: "start",
                textAlign: "center",
                minHeight: 80,
              }}
            >
              <img
                src={`/static/glass_type_${product.glassware}.png`}
                style={{ width: 50 }}
              />
            </Box>
            <Box ml={0} sx={{ width: "100%", height: "100%" }}>
              <Stack spacing={1} justifyItems="space-between">
                {/* <Typography variant="body2" color="text.secondary">
                  {product.description.slice(0,50)}
                </Typography> */}
                <Typography variant="body2" color="text.secondary">
                  Beskrivelse: {product.ingredients}
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  style={{ textTransform: "capitalize" }}
                >
                  Profil: {product.flavorProfile}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Sted: {product.allergens}
                </Typography>
              </Stack>
            </Box>
          </Stack>
        </CardContent>
        {/* <CardActions disableSpacing sx={{ pt: 0 }}>
          <Box sx={{ margin: "0 0 0 auto" }}>
            <IconButton onClick={() => decrementItemCount(product)}>
              <RemoveIcon />
            </IconButton>
            {(item && item.count) || 0}
            <IconButton
              color="tertiary"
              onClick={() => incrementItemCount(product)}
            >
              <AddIcon />
            </IconButton>
          </Box>
        </CardActions> */}
      </Card>
    );
  }, [product, item]);

  return cardContent;
}

DrinkCard.propTypes = {
  product: PropTypes.object,
};

// ExpandMore.propTypes = {
//   expand: PropTypes.bool
// }

export default DrinkCard;
