import { rest } from "msw";
import { db } from "./db";
import { origin } from "./baseUrl";

export const handlers = [
  rest.get(`${origin}/api/v1/products`, async (req, res, ctx) => {
    const params = Object.fromEntries(req.url.searchParams.entries());
    const drinks = await db.get("drinks", params);
    // await new Promise((res) => setTimeout(() => {res()}, 1000));

    console.log(drinks);
    return res(ctx.status(200), ctx.json(drinks));
  }),
];
