import { configureStore } from '@reduxjs/toolkit'
import appReducer from './appSlice'
import cartReducer from './cartSlice'
import preferenceReducer from './preferenceSlice'

export default configureStore({
  reducer: {
    app: appReducer,
    cart: cartReducer,
    preferences: preferenceReducer,
  }
});