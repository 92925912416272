import React, { useCallback } from 'react';
import {useNavigate} from 'react-router-dom';
import Toolbar from '@mui/material/Toolbar';
// import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';
// import Button from '@mui/material/Button';
// import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import HomeIcon from '@mui/icons-material/Home';
// import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Menu from './Menu';
// import {useSelector} from 'react-redux';
// import {Link} from 'react-router-dom';
import { AppBar } from '@mui/material';
import {useTheme} from '@mui/material/styles';

// import {endRound} from '../store/cartSlice';

export default function Header() {
  // const dispatch = useDispatch();
  const navigate = useNavigate();
  // const currentCount = useSelector(state => state.cart.currentRound.count);
  // const totalCount = useSelector(state => state.cart.count);
  const {palette} = useTheme();

  const refreshApp = useCallback(() => {
    window.location.replace(window.location.origin);
  });

  // const navigateCheckout = useCallback(() => {
  //   // dispatch(endRound());
  //   navigate('/checkout');
  // });

  // const shoppingCart = () => {
  //   let content;

  //   if (currentCount) {
  //     content = (
  //       <IconButton onClick={navigateCheckout}>
  //         <Badge badgeContent={currentCount} color="tertiary">
  //           <ShoppingCartIcon color="action"/>
  //         </Badge>
  //       </IconButton>
  //     );
  //   } else if (totalCount) {
  //     content = (
  //       <IconButton onClick={navigateCheckout}>
  //         <Badge badgeContent="Pay" color="tertiary">
  //           <ShoppingCartIcon color="action"/>
  //         </Badge>
  //       </IconButton>
  //     );
  //   }

  //   return content;
  // }

  return (
    <AppBar sx={{backgroundColor: "#fff", color: palette.text.primary, boxShadow: 0}}>
      <Toolbar sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Box flex={1} mt={1} align="left" onClick={refreshApp}>
          <img src="/static/mockup_logo2.png" width="80px" alt="Company Logo"></img>
        </Box>
        {/* {shoppingCart()} */}
        <IconButton onClick={() => navigate('/')} >
          <HomeIcon />
        </IconButton>
        <Menu />
      </Toolbar>
    </AppBar>
  );
}
