import React from "react";
import { Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setProgress } from "../store/appSlice";

import Main from "../components/Main";
import Footer from "../components/Footer";
import DrinkList from "../components/DrinkList";
// import { useNavigate } from 'react-router-dom';

export const ResultsPage = () => {
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  // const total = useSelector(state => state.cart.total);
  const preferences = useSelector((state) => state.preferences.current);

  React.useEffect(() => {
    dispatch(setProgress(95));
  }, []);

  // const payOrder = React.useCallback(() => {
  //   navigate('/checkout');
  // })

  return (
    <React.Fragment>
      <Main>
        <Box flex={1} mt={2} align="center">
          <img
            src="/static/vinfestival.png"
            width="100px"
            alt="Company Logo"
          ></img>
        </Box>
        <DrinkList params={preferences} pageCount={3} />
      </Main>
      {/* <Footer actions={
        <>
          {total && <><Button color="tertiary" size="large" onClick={payOrder}>go to cart</Button>{' '}</>}  
        </>
      } /> */}
      <Footer />
    </React.Fragment>
  );
};
