import React from "react";
import { Typography, Stack, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setProgress } from "../store/appSlice";

import Main from "../components/Main";
import Footer from "../components/Footer";
import CircularButton from "../components/CircularButton";

export const PickerPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(setProgress(0));
  }, []);

  return (
    <React.Fragment>
      <Main>
        <Box flex={1} mt={2} align="center">
          <img
            src="/static/vinfestival.png"
            width="100px"
            alt="Company Logo"
          ></img>
        </Box>
        <Box my={2}>
          <Stack spacing={6}>
            <Typography
              variant="h5"
              mb={1}
              style={{ textAlign: "center", marginTop: "1rem" }}
            >
              La oss finne noe du liker!
            </Typography>
            <Box mb={5} mt={1}>
              <Stack direction="row" justifyContent="space-between">
                <CircularButton
                  sx={{ margin: "5px" }}
                  onClick={() => navigate("/step1")}
                >
                  Finn viner du bør prøve
                </CircularButton>
                <CircularButton
                  sx={{ margin: "5px" }}
                  onClick={() => navigate("/menu")}
                >
                  Hele vinlisten
                </CircularButton>
              </Stack>
            </Box>
            {/* <Button sx={{py: 2}}>
              Try our food pairing with cocktails
            </Button> */}
          </Stack>
        </Box>
      </Main>
      <Footer back={false} />
    </React.Fragment>
  );
};
