import drinksList from "./data/drinks.json";

class Drink {
  constructor(attrs) {
    // const units = 'NOK';
    // let price;

    // if (!attrs.PRICE) {
    //     price = 0;
    // } else if (typeof attrs.PRICE === 'string') {
    //     price = attrs.PRICE.match(/([\d.]+)/).slice(1);
    // } else {
    //     price = attrs.PRICE;
    // }

    this.name = attrs["COCKTAIL NAME"];
    this.price = attrs.PRICE ?? "";
    // this.priceUnits = units;
    this.ingredients = attrs.INGREDIENTS;
    this.allergens = attrs.ALLERGENS;
    this.method = attrs.METHOD;
    this.garnish = attrs.GARNISH;
    this.availability = attrs.AVAILABILITY;
    this.flavorProfile = attrs["FLAVOUR PROFILE"];
    this.choice1 = attrs["CHOICE 1"];
    this.baseSpirit = attrs["BASE SPIRIT"];
    this.glassware = attrs["GLASSWARE NUMBER"];
    this.glasswareName = attrs["GLASSWARE NAME"];
    this.number = attrs.NUMBER;
    this.comments = attrs["SPECIAL COMMENTS"];
  }
}
function shuffleArray(array) {
  const shuffledArray = [...array];
  for (let i = shuffledArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
  }
  return shuffledArray;
}

const DRINKS_PER_PAGE = 3;
let __drinks = drinksList.map((drink) => new Drink(drink));
let drinks = shuffleArray(__drinks);

const getDrinks = async (params) => {
  console.log("getDrinks");
  console.log(params);

  const matchFlavorProfile = (drink, val) => {
    console.log(drink.flavorProfile);
    console.log(val);
    let wholeProfile = drink.flavorProfile;
    let profile = wholeProfile.toLowerCase().split(", ");
    let searchValue = "" + val;
    searchValue = searchValue.toLowerCase();

    return profile.includes(searchValue);
  };

  const matchBoozeLevel = (drink, val) => drink.choice1.includes(val);

  const matchLiquors = (drink, val) =>
    drink.baseSpirit ? val[drink.baseSpirit]?.value ?? true : true;

  const matchingDrinks = drinks.filter((drink) => {
    let match = true;

    for (let name in params) {
      let value = params[name];

      if (name === "boozy") {
        match = matchBoozeLevel(drink, value);
      }
      if (name === "flavorProfile") {
        match = matchFlavorProfile(drink, value);
      }
      if (name === "liquors") {
        match = matchLiquors(drink, JSON.parse(value));
        // else if (name === "available") match = matchAvailability(drink);
      }

      if (!match) {
        break;
      }
    }

    return match;
  });

  const page = Number(params.page);
  const pageCount = Number(params.pageCount) || DRINKS_PER_PAGE;
  const start = page * pageCount;

  let drinksPromise = new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        drinks: matchingDrinks.slice(start, start + pageCount),
        hasMore: start < matchingDrinks.length - pageCount,
      });
    }, 1000);
  });

  return drinksPromise;
};

async function get(name, params = {}) {
  try {
    switch (name) {
      case "drinks":
        return await getDrinks({ available: true, ...params });
      default:
        throw new Error("Invalid query");
    }
  } catch (e) {
    console.error(`Error fetching products.`, e);
  }
}

async function add(name, item) {
  switch (name) {
    case "drinks":
      drinks.push(item);
      break;
  }
}

async function remove(name, item) {
  switch (name) {
    case "drinks":
      drinks = drinks.filter((d) => d.name !== item.name);
      break;
  }
}

export const db = {
  get,
  add,
  remove,
};
