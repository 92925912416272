import React, { useEffect, useCallback } from "react";
import { Typography, Grid, Box, Stack, Chip, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setProgress } from "../store/appSlice";
import { set as setPreference } from "../store/preferenceSlice";

import Main from "../components/Main";
import Footer from "../components/Footer";

export const PickerStep2Page = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const preferences = useSelector((state) => state.preferences.current);
  let tasteLabel = "";

  if (preferences.boozy === "white") {
    tasteLabel = "Hvit";
  } else if (preferences.boozy === "rose") {
    tasteLabel = "Rosé";
  } else if (preferences.boozy === "sparkling") {
    tasteLabel = "Musserende";
  } else {
    tasteLabel = "Rød";
  }

  useEffect(() => {
    dispatch(setProgress(40));
  }, []);

  const setAndNavigate = useCallback((name, value, href = "/step3") => {
    dispatch(setPreference({ name, value: value }));

    console.log(href);
    // navigate("/results");
    navigate(href);
    // if (preferences.boozy === "mock") {
    //   navigate("/results");
    // } else {
    //   navigate(href);
    // }
  }, []);

  return (
    <React.Fragment>
      <Main>
        <Box flex={1} mt={2} align="center">
          <img
            src="/static/vinfestival.png"
            width="100px"
            alt="Company Logo"
          ></img>
        </Box>
        <Stack spacing={2} my={2}>
          <Stack direction="row" spacing={1} mb={2} mt={2}>
            <Chip label={tasteLabel} color="warning" variant="outlined" />
          </Stack>
          <Box>
            <Typography variant="h5" sx={{ marginBottom: 4 }}>
              Hvilken stil av vin ønsker du deg?
            </Typography>
          </Box>
          <Grid container rowSpacing={4} alignItems="center">
            {["Full bodied", "Light", "Funky", "Fruity", "Oaky", "Fresh"].map(
              (flavor, index) => (
                <Grid
                  item
                  key={index}
                  xs={6}
                  style={{ paddingTop: index < 2 && 0, textAlign: "center" }}
                >
                  <Button
                    style={{ width: "90%" }}
                    onClick={() => {
                      console.log(flavor);
                      setAndNavigate("flavorProfile", flavor);
                    }}
                  >
                    {flavor}
                  </Button>
                </Grid>
              )
            )}
          </Grid>
        </Stack>
      </Main>
      <Footer />
    </React.Fragment>
  );
};
