import React, { useEffect, useCallback, useRef } from "react";
import {
  Typography,
  Grid,
  Box,
  Stack,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Button,
  Chip,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setProgress } from "../store/appSlice";
import { set as setPreference } from "../store/preferenceSlice";

import Main from "../components/Main";
import Footer from "../components/Footer";

export const PickerStep3Page = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { liquors, flavorProfile, boozy } = useSelector(
    (state) => state.preferences.current
  );
  const form = useRef();

  let profileLabel = flavorProfile;
  let tasteLabel = "";

  console.log(flavorProfile);
  console.log(boozy);

  if (boozy === "white") {
    tasteLabel = "Hvit";
  }
  if (boozy === "rose") {
    tasteLabel = "Rosé";
  }
  if (boozy === "sparkling") {
    tasteLabel = "Musserende";
  }
  if (boozy === "red") {
    tasteLabel = "Rød";
  }

  useEffect(() => {
    dispatch(setProgress(75));
  }, []);

  /*
  
  liquors: {
    dry: { id: "dry", name: "Ingenting, det skal være tørt", value: true },
    offdry: {
      id: "offdry",
      name: "Litt sødme, men fremdeles tørt",
      value: true,
    },
    semisweet: {
      id: "semisweet",
      name: "Det som smaker litt søtt",
      value: true,
    },
  */
  const updateLiquorPreference = (name, value) => {
    const liquorPrefs = { ...liquors, [name]: { ...liquors[name], value } };
    console.log("val:", liquorPrefs);
    dispatch(setPreference({ name: "liquors", value: liquorPrefs }));
  };

  const setAndNavigate = useCallback((name, value, href = "/results") => {
    // if (!form.current) { return; }
    // const formData = new FormData(form.current);
    // const params = [...formData].reduce((m, entry) => {
    //   m[entry[0].toLowerCase()] = entry[1] === 'on';
    //   return m;
    // }, {});

    // dispatch(setPreference({name: 'ingredients', value: params}));
    navigate(href);
  }, []);

  return (
    <React.Fragment>
      <Main>
        <Box flex={1} mt={2} align="center">
          <img
            src="/static/vinfestival.png"
            width="100px"
            alt="Company Logo"
          ></img>
        </Box>
        <Box my={2}>
          <Stack direction="row" spacing={1} mb={2} mt={2}>
            <Chip label={tasteLabel} color="warning" variant="outlined" />
            <Chip label={profileLabel} color="warning" variant="outlined" />
          </Stack>
          <Stack spacing={3} alignItems="center">
            <Typography variant="h5">
              Hvor mye sødme vil du ha i smaken? Er du usikker kan du velge
              flere.
            </Typography>
            <form ref={form}>
              {/* <Grid container rowSpacing={0} columnSpacing={4} px={4}> */}
              {Object.values(liquors).map(({ id, name, value }, index) => (
                <Grid item xs={12} key={index}>
                  {console.log(id, name, value)}
                  <FormGroup>
                    <FormControlLabel
                      label={name}
                      control={
                        <Checkbox
                          name={name}
                          checked={value}
                          onChange={() => updateLiquorPreference(id, !value)}
                        />
                      }
                    />
                  </FormGroup>
                </Grid>
              ))}
              {/* </Grid> */}
            </form>
          </Stack>
        </Box>
      </Main>
      <Footer
        actions={
          <Button color="secondary" onClick={() => setAndNavigate()}>
            Vis vinene mine
          </Button>
        }
      />
    </React.Fragment>
  );
};
