import { createSlice } from '@reduxjs/toolkit'

const initialValue = () => (
  {
    payLater: false,
    paymentMethod: 'vipps',
    total: 0, 
    count: 0,
    currentRound: blankRound(),
    rounds: []
  }
);

const blankRound = () => (
  {
    total: 0,
    count: 0,
    items: {},
  }
);

export const cartSlice = createSlice({
  name: 'cart',
  initialState: initialValue(),
  reducers: {
    endRound: (state) => {
      // state.count += state.currentRound.count;
      // state.total += state.currentRound.total;
      state.rounds.push(state.currentRound);
      state.currentRound = blankRound();
    },

    setPayLater: (state, {payload}) => {
      state.payLater = payload;
      return state;
    },

    setPaymentMethod: (state, {payload}) => {
      state.paymentMethod = payload;
      return state;
    },

    addItem: (state, {payload}) => {
      const current = state.currentRound;
      current.count += 1;
      current.total += payload.price;

      state.count += 1;
      state.total += payload.price;

      if (current.items[payload.name]) {
        current.items[payload.name].count += 1;
      } else {
        current.items[payload.name] = {
          ...payload,
          count: 1
        }
      }

      return state;
    },

    removeItem: (state, {payload}) => {
      const current = state.currentRound;
      const item = current.items[payload.name];

      if (item?.count > 0) {
        current.total -= payload.price;
        current.count -= 1;
        state.total -= payload.price;
        state.count -= 1;
        item.count -= 1;
      }

      if (item?.count === 0) {
        delete current.items[payload.name];
      }

      return state;
    },
    
    removeProduct: (state, {payload}) => {
      const current = state.currentRound;
      const count = current.items[payload.name].count;

      if (count) {
        current.total -= (payload.price * count);
        current.count -= count;
        state.total -= (payload.price * count);
        state.count -= count;
  
        delete current.items[payload.name];
      }

      return state;
    },
    emptyCart: () => {
      return initialValue();
    },
  }
});

export const { addItem, removeItem, removeProduct, emptyCart, endRound, setPayLater, setPaymentMethod } = cartSlice.actions

export default cartSlice.reducer
