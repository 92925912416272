import { createSlice } from '@reduxjs/toolkit'

export const appSlice = createSlice({
  name: 'app',
  initialState: {
    progress: 0,
    paymentMethod: null,
  },
  reducers: {
    setProgress: (state, {payload}) => {
      state.progress = payload;
      return state;
    },
    savePaymentMethod: (state, {payload}) => {
      state.paymentMethod = payload;
      return state;
    },
  }
})

export const { setProgress, savePaymentMethod } = appSlice.actions

export default appSlice.reducer