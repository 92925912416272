import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
// import { useNavigate } from 'react-router-dom';

// import {Button} from '@mui/material';

import { reset } from '../store/preferenceSlice';
import Main from '../components/Main';
import Footer from '../components/Footer';
import DrinkList from '../components/DrinkList';

export const MenuPage = () => {
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  // const total = useSelector(state => state.cart.total);

  useEffect(() => {
    dispatch(reset());
  }, []);
  
  // const payOrder = React.useCallback(() => {
  //   navigate('/checkout');
  // });

  return (
    <React.Fragment>
      <Main>
        <DrinkList params={{}}/>
      </Main>
      {/* <Footer actions={
        <>
          {total && <><Button color="tertiary" size="large" onClick={payOrder}>go to cart</Button>{' '}</>}
        </>
      } /> */}
      <Footer />
    </React.Fragment>
  );
}