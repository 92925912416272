import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
// import Stack from '@mui/material/Stack';
// import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
// import Paper from '@mui/material/Paper';
// import Link from '@mui/material/Link';
// import {BottomNavigation} from '@mui/material'
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import LinearProgress from '@mui/material/LinearProgress';
import BackIcon from '@mui/icons-material/ArrowBackIos';
import {useNavigate} from 'react-router-dom';

import {useSelector} from 'react-redux';
import { AppBar } from '@mui/material';

function Copyright() {
  return (
    <Typography variant="caption" color="text.secondary" align="center" mb={1}>
      {'Copyright © '}
      <Link color="inherit" href="https://pickyourpour.com/">
        PickYourPour
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

function Footer({back, actions}) {
  const progress = useSelector(state => state.app.progress);
  const navigate = useNavigate();
  const navigateBack = () => {
    if (window.location.pathname !== '/') {
      navigate(-1)
    }
  }

  const toolbar = () => {
    if (back || actions) {
      return (
        <Toolbar sx={{mb: 0}}>
          <Box sx={{ flex: 1}} onClick={navigateBack}>
            {back && (
              <>
                <Button variant="text" color="secondary">
                  <BackIcon />
                  <Typography variant="subtitle" color="secondary">
                    Back
                </Typography>
                </ Button>
              </>
            )}
          </Box>
          <Box>
            {actions}
          </Box>
        </Toolbar>
      );
    }
  }

  return (
    <AppBar position="fixed" sx={{ top: 'auto', bottom: 0, backgroundColor: '#fff' }}>
      {toolbar()}
      <Copyright />
      {progress > 0 && (
        <Box position="fixed" sx={{bottom: 0, padding: 0, marginTop: 0, width: '100%' }}>
          <LinearProgress variant="determinate" value={progress} />
        </Box>
      )}
    </AppBar>
  );
}

Footer.defaultProps = {
  back: true,
};

Footer.propTypes = {
  back: PropTypes.bool,
  actions: PropTypes.node,
};

export default Footer;