import * as React from 'react';
import PropTypes from 'prop-types';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import {grey} from '@mui/material/colors';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  maxWidth: 400,
  bgcolor: 'background.paper',
  border: `1px solid ${grey[500]}`,
  boxShadow: 10,
  p: 4,
  borderRadius: 5,
};

export default function TransitionsModal({openAt, children}) {
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    const val = openAt !== false && openAt;
    setOpen(!!val);
  }, [openAt]);

  const handleClose = () => setOpen(false);

  return (
    <div>
      <Modal
        // sx={{maxWidth: 400}}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 250,
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            {children}
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

TransitionsModal.propTypes = {
  openAt: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.number,
  ]),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
};