import React from 'react';
import PropTypes from 'prop-types';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { grey } from '@mui/material/colors';

const theme = createTheme({
  palette: {
    text: {
      primary: '#333',
    },
    primary : {
      main: '#47245E',
      light: '#633F71',
      dark: '#47245E',
      contrastText: '#FFF',
    },
    secondary : {
      main: '#ED7B3B',
      light: '#F6AE7F',
      dark: '#ED7B3B',
      contrastText: '#000',
    },
    tertiary: {
      main: '#E42276',
      light: '#EE88A7',
      dark: '#E42276',
      contrastText: '#fff',
    },
    danger: {
      main: '#fe0000',
      light: '#fe0000',
      dark: '#fe0000',
      contrastText: '#FFF',
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        variant: 'contained'
      },
      styleOverrides: {
        root: {
          borderRadius: 100,
          textTransform: 'capitalize',
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        root: {
          textTransform: 'capitalize',
        }
      }
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 20,
        }
      }
    },
    MuiModal: {
      styleOverrides: {
        root: {
          borderRadius: 20,
          borderWidth: 1,
          borderColor: grey[700],
        }
      }
    }
  }
});

const Theme = ({children}) => (
  <ThemeProvider theme={theme}>
    {children}
</ThemeProvider>
);

Theme.propTypes = {
  children: PropTypes.node
}

export default Theme;

