import React from "react";
import { PropTypes } from "prop-types";
import { Stack, Typography, Button, Box } from "@mui/material";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { getProducts } from "../services/ProductQuery";
import DrinkCard from "../components/DrinkCard";
import TransitionsModal from "../components/TransitionsModal";

export default function DrinkList({ params, pageCount = 100 }) {
  const [page, setPage] = React.useState(0);
  const [showSelectionModal, setShowSelectionModal] = React.useState();
  const currentCount = useSelector((state) => state.cart.currentRound.count);

  // const currentCount = React.useRef(0);
  const prevCount = React.useRef(0);

  const { data, error, isFetching } = useQuery(
    ["getResults", page + 1],
    () => getProducts({ page, pageCount, ...params }),
    { keepPreviousData: false, staleTime: 1000 }
  );

  const nextPage = React.useCallback(() => {
    setPage((page) => page + 1);
  }, [page]);

  React.useEffect(() => {
    if (prevCount.current === 0 && currentCount > 0) {
      setShowSelectionModal(Date.now());
    }

    prevCount.current = currentCount;
  }, [currentCount]);

  if (isFetching) {
    return (
      <img
        className="loading_animation"
        src="static/sorteringsanimasjon_1.gif"
      />
    );
  } else if (data?.drinks?.length === 0) {
    return (
      <Stack spacing={2} alignItems="center" mt={3} direction="row">
        <img
          src="static/Error-01.png"
          width="100px"
          alt="No cocktails found"
        ></img>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          Det var dessverre ingen viner som passet søket ditt:( gå tilbake
          forandre noe.)
        </Typography>
      </Stack>
    );
  } else {
    console.log(data);
    return (
      <>
        {error && (
          <Typography variant="h5">
            Error
            <Typography variant="body2">{error}</Typography>
          </Typography>
        )}
        <>
          <TransitionsModal openAt={showSelectionModal}>
            <Typography>Continue to Order</Typography>
            <Typography color="tertiary.main">
              To place your order, please proceeed to the checkout.
            </Typography>
            <Box display="flex" mt={2}>
              <Button
                fullWidth
                sx={{ margin: "auto" }}
                onClick={() => setShowSelectionModal(false)}
              >
                Checkout
              </Button>
            </Box>
          </TransitionsModal>
          <Stack spacing={2} alignItems="center" mt={3}>
            {data.drinks?.map((product) => (
              <DrinkCard key={product.name} product={product} />
            ))}
          </Stack>
          {data.hasMore && (
            <Box
              justifyContent="center"
              display="flex"
              style={{ width: "100%", marginTop: 10 }}
            >
              <Button size="large" color="secondary" onClick={nextPage}>
                show more options
              </Button>
            </Box>
          )}
        </>
      </>
    );
  }
}

DrinkList.propTypes = {
  params: PropTypes.object,
  pageCount: PropTypes.number,
};
