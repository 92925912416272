import React, { useCallback, useEffect } from "react";
import { Typography, Stack, Box, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setProgress } from "../store/appSlice";
import { set as setPreference } from "../store/preferenceSlice";

import Main from "../components/Main";
import Footer from "../components/Footer";

export const PickerStep1Page = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setProgress(10));
  }, []);

  const setAndNavigate = useCallback((name, value, href = "/step2") => {
    dispatch(setPreference({ name, value }));
    navigate(href);
  }, []);

  return (
    <React.Fragment>
      <Main>
        <Box flex={1} mt={2} align="center">
          <img
            src="/static/vinfestival.png"
            width="100px"
            alt="Company Logo"
          ></img>
        </Box>
        <Box my={2}>
          <Stack spacing={3} alignItems="center">
            <Typography variant="h5" mb={2}>
              Hva slags vin har du lyst på?
            </Typography>
            <Stack spacing={4} justifyContent="space-between">
              <Button
                sx={{ py: 2 }}
                style={{
                  minWidth: "300px",
                }}
                onClick={() => setAndNavigate("boozy", "red")}
              >
                Rød
              </Button>
              <Button
                sx={{ py: 2 }}
                onClick={() => setAndNavigate("boozy", "white")}
              >
                Hvit
              </Button>
              <Button
                sx={{ py: 2 }}
                onClick={() => setAndNavigate("boozy", "sparkling")}
              >
                Musserende
              </Button>
              <Button
                sx={{ py: 2 }}
                onClick={() => setAndNavigate("boozy", "rose")}
              >
                Rosé
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Main>
      <Footer />
    </React.Fragment>
  );
};
