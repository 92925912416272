import React from 'react';
import Button from '@mui/material/Button';
import './CircularButton.css';

export default function CircularButton(props) {

  return (
    <div className='circular-button'>
      <div className='button-outer-container'>
        <div className='button-inner-container'>
          <Button {...props} />
        </div>
      </div>
    </div>
  );
}